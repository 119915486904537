const modal = {
  state: {
    resetSuccess: false,
    goToLoginModal: false
  },
  mutations: {
    updateResetSuccess (state) {
      state.resetSuccess = !state.resetSuccess
    },
    updateGoToLoginModal (state) {
      state.goToLoginModal = !state.goToLoginModal
    }
  }
}
export default modal
