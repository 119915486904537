import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import getGoogleAnalyticsIds from './utils/googleAnalytics'
import { init as initFS } from '@fullstory/browser'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import 'emerald-workbench/dist/emerald.pagarme.min.css'

Vue.config.productionTip = false

if (['production'].includes(process.env.VUE_APP_CORE_ENV)) {
  initFS({ orgId: 'BKP05' })

  const ids = getGoogleAnalyticsIds()
  ids.length &&
    Vue.use(VueAnalytics, {
      id: ids,
      router,
      debug: {
        enabled: false, // Turn on for activate debuging
        sendHitTask: process.env.VUE_APP_CORE_ENV
      }
    })
}

const recaptchaV3Flag = Number(process.env.VUE_APP_RECAPTCHA_VERSION)
const isRecaptchaV3 = recaptchaV3Flag === 3

if (isRecaptchaV3) {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY_V_THREE,
    loaderOptions: {
      explicitRenderParameters: {
        badge: 'bottomleft'
      }
    }
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
