import { render, staticRenderFns } from "./Recaptchav2Step.vue?vue&type=template&id=95e05154&lang=html&"
import script from "./Recaptchav2Step.vue?vue&type=script&lang=js&"
export * from "./Recaptchav2Step.vue?vue&type=script&lang=js&"
import style0 from "./Recaptchav2Step.vue?vue&type=style&index=0&id=95e05154&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports