import { env } from '@/store'

export function validitySession () {
  try {
    const session = sessionStorage.getItem(`${env}mp_si`)

    const sessionObject = JSON.parse(session)

    // Verifica se está dentro do tempo de expiração
    const validity = sessionObject.expires >= Date.now()

    // Se não for valido, remove e retorna undefined
    if (!validity) {
      sessionStorage.removeItem(`${env}mp_si`)
      return undefined
    }

    return session
  } catch(_err) {
    return undefined
  }
}
