<template>
  <div>
    <div
      class="el-section el-centered"
      v-if="isLoading && !hasError"
    >
      <emd-pagarme-loader
        class="fade-in"
        :color="'secondary'"
      />
    </div>
    <global-error
      v-if="!isLoading && hasError"
      :theme="'pagarme'"
    />
    <div
      v-if="!isLoading && !hasError"
      class="el-centered fade-in"
    >
      <div
        class="el-stripe"
        style="margin: auto"
      >
        <div class="el-bounds _narrow">
          <div class="el-centered__main">
            <!-- Login -->
            <div class="el-centered__main_body">
              <div class="el-section">
                <router-view></router-view>
              </div>
              <!-- Footer -->
              <emd-footer-external
                class="eu-display_flex eu-justify_center"
              ></emd-footer-external>
              <!-- ./Footer -->
            </div>
            <!-- /Login -->
          </div>
        </div>
      </div>
      <two-fa-reset-success></two-fa-reset-success>
    </div>
  </div>
</template>

<script>
import EmdFooterExternal from '@/components/emerald/organisms/EmdFooterExternal.vue'
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue/src/components'
import TwoFaResetSuccess from '@/views/TwoFaReset/TwoFaResetSuccess.vue'

export default {
  name: 'SignTemplate',
  components: {
    EmdFooterExternal,
    GlobalError,
    EmdPagarmeLoader,
    TwoFaResetSuccess
  },
  computed: {
    isLoading: function () {
      return this.$store.state.globalLoading
    },
    hasError: function () {
      return this.$store.state.globalError
    },
    pref: function () {
      return this.$store.getters.pref
    }
  },
  mounted () {
    const body = document.body
    const preferences = Object.assign({}, this.pref)
    if (preferences.appearance.mode === 'light') {
      body.classList.remove('_mode_dark')
    } else {
      body.classList.add('_mode_dark')
    }
    this.$store.dispatch('SET_PREFERENCES', preferences)
  },
  methods: {
    goToSignin () {
      this.$router.push({ name: 'signin' })
    }
  }
}
</script>
<style>
.emd-list svg {
  display: inline-block;
  vertical-align: top;
}
.el-centered__main_aside .emd-list .emd-text {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  width: 85%;
}
</style>
