import Vue from 'vue'
import Vuex from 'vuex'
import IDCore from 'id-restrict-js'
import UtilitiesCore from 'utilities-js'
import Cookies from 'js-cookie'
import { ProfileCore } from 'profile-ts'
import { log as logFS } from '@fullstory/browser'

import role from './modules/role'
import whitelabel from './modules/whitelabel'
import modal from './modules/modal'
import redirect from './modules/redirect'

import { version } from '@/../package.json'
import { dataCore, dataCoreProfile } from '@/utils/dataCores'

Vue.use(Vuex)

export const IDInstance = new IDCore(dataCore)
IDInstance.setCallbackError(dataCoreProfile.setCallbackError)

export const UtilitiesInstance = new UtilitiesCore(dataCore)
export const ProfileInstance = new ProfileCore(dataCoreProfile)

export const env = UtilitiesInstance.envAcronym

const store = new Vuex.Store({
  modules: {
    role,
    whitelabel,
    modal,
    redirect
  },
  state: {
    tfaLoginBody: {},
    userTwoFactorAuthentication: {},
    tfaMaskedPhone: '',
    redirectApp: '',
    user: {
      email: '',
      invites: {
        items: [],
        pagination: {}
      }
    },
    globalLoading: false,
    globalError: false,
    maintenance: false,
    globalErrorCode: '',
    feedbackType: '',
    validated: {
      value: {},
      type: undefined
    },
    timezones: [],
    username: null,
    pref: ProfileInstance.me.getPreferences(),
    appVersion: version,
    totalUserFail: 0,
    showCaptchav2: false,
    requiredCaptchav2: false,
  },
  mutations: {
    SET_GLOBAL_ERROR (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
      if (status && code !== 404 && code !== 403)
        logFS('error', 'Error: redirected to screen 500')
    },
    SET_USER_EMAIL (state, email) {
      state.user.email = email
    },
    SET_TFA_LOGIN_BODY (state, body) {
      state.tfaLoginBody = Object.assign(state.tfaLoginBody, body)
    },
    SET_TFA_MASKED_PHONE (state, payload) {
      state.tfaMaskedPhone = payload
      sessionStorage.setItem(`${env}mp_maskedPhone`, payload)
    },
    GET_MASKED_PHONE (state) {
      const maskedPhoneInStorage = sessionStorage.getItem(
        `${env}mp_maskedPhone`
      )
      if (maskedPhoneInStorage) {
        state.tfaMaskedPhone = maskedPhoneInStorage
      }
    },
    GET_TFA_PHONE (state) {
      const phoneInStorage = sessionStorage.getItem(`${env}mp_phone`)
      if (phoneInStorage) {
        state.tfaPhone = JSON.parse(phoneInStorage)
      }
    },
    SET_USER_TFA (state, tfa) {
      state.userTwoFactorAuthentication = Object.assign(
        state.userTwoFactorAuthentication,
        tfa
      )
      sessionStorage.setItem(
        `${env}mp_mfa`,
        JSON.stringify(state.userTwoFactorAuthentication)
      )
    },
    GET_USER_TFA (state) {
      const mfaCookie = sessionStorage.getItem(`${env}mp_mfa`)
      if (mfaCookie) {
        const mfaJson = JSON.parse(mfaCookie)
        state.userTwoFactorAuthentication = Object.assign(
          state.userTwoFactorAuthentication,
          mfaJson
        )
      }
    },
    SET_INVITES (state, invites) {
      state.user.invites = invites
    },
    SET_SIGN_LOADING (state, loaderState) {
      state.globalLoading = loaderState
    },
    SET_FEEDBACK_TYPE (state, payload) {
      state.feedbackType = payload
    },
    SET_VALIDATED (state, payload) {
      state.validated = payload
    },
    SET_TIMEZONES (state, payload) {
      state.timezones = payload
    },
    SET_USERNAME (state, username) {
      state.username = username
    },
    SET_PREF (state, pref) {
      state.pref = Object.assign(state.pref, pref)
    },
    SET_USER_FAIL (state, value) {
      state.totalUserFail = value
      Cookies.set(`${env}mp_fl`, btoa(value), { domain: IDInstance.domain })
    },
    SHOW_CAPTCHA (state, value) {
      state.showCaptchav2 = value
    },
    SET_REQUIRED_CAPTCHA_V2 (state, value) {
      state.requiredCaptchav2 = value
    }
  },
  getters: {
    token: () => () => {
      return IDInstance.user.getToken()
    },
    GET_FEEDBACK_TYPE (state) {
      return state.feedbackType
    },
    GET_VALIDATED (state) {
      return state.validated
    },
    invite: state => {
      return state.user.invites.items[0]
    },
    totalInvites: state => {
      return state.user.invites.pagination.total_items
    },
    pref (state) {
      return state.pref
    },
    appVersion (state) {
      return state.appVersion
    },
    userEmail (state) {
      return state.user.email
    },
    GET_GLOBAL_ERROR_CODE (state) {
      return state.globalErrorCode
    },
    totalUserFail (state) {
      return state.totalUserFail
    },
    showCaptchav2 (state) {
      const isRecaptchaV2 = Number(process.env.VUE_APP_RECAPTCHA_VERSION) === 2;
      const { totalUserFail, showCaptchav2, requiredCaptchav2 } = state;
      const shouldShowRecaptcha = isRecaptchaV2 && (totalUserFail >= 2 || showCaptchav2);

      return shouldShowRecaptcha || requiredCaptchav2;
    },
    maintenance (state) {
      return state.maintenance
    }
  },
  actions: {
    CREATE_AUTHORIZATION ({ getters }, data) {
      const versionCaptcha = getters.showCaptchav2 ? 'v2' : 'v3'
      const config = { headers: { 'x-captcha-version': versionCaptcha }}
      return IDInstance.auth.connect.createToken(data, config)
    },
    CREATE_TFA_DATA ({ commit }, tfa) {
      commit('SET_USER_TFA', tfa)
    },
    SET_LOGIN_DATA_TFA ({ commit }, body) {
      commit('SET_TFA_LOGIN_BODY', body)
    },
    REMOVE_SESSION_ID () {
      const sessionID = sessionStorage.getItem(`${env}mp_si`)
      if (sessionID) {
        sessionStorage.removeItem(`${env}mp_si`)
      }
    },
    LOGIN ({ dispatch }) {
      dispatch('REMOVE_SESSION_ID')
      dispatch('UPDATE_SIGN_LOADING', true)
      dispatch('GO_TO_REDIRECT_URL')
    },
    SET_REDIRECT_URL ({ _dispatch }, app) {
      IDInstance.operation.setRedirectUrl(app)
    },
    MFA_CREATE (context) {
      const { email } = context.state.userTwoFactorAuthentication
      const body = { ...context.state.userTwoFactorAuthentication }
      delete body.email
      return ProfileInstance.mfa.createUserMFA(email, body)
    },
    MFA_ACTIVATE (context, code) {
      const { email } = context.state.userTwoFactorAuthentication
      return ProfileInstance.mfa.getActiveUserMFA(email, code)
    },
    MFA_SEND (context) {
      const token = context.getters.token()
      if (token) {
        return ProfileInstance.me.sendUserMFA()
      }

      const email = context.state.userTwoFactorAuthentication?.email || context.state.user?.email
      return ProfileInstance.mfa.sendUserMFA(email)
    },
    MFA_RESET (context, data) {
      return ProfileInstance.mfa.resetMFaUser(context.state.user.email, data)
    },
    CREATE_REGISTER ({ commit }, userData) {
      return IDInstance.operation.register(userData)
    },
    RESET_PASSWORD (context, email) {
      return IDInstance.operation.resetPassword(email)
    },
    UPDATE_SIGN_LOADING ({ commit }, loaderState) {
      commit('SET_SIGN_LOADING', loaderState)
    },
    DELETE_TOKENS () {
      return IDInstance.user.deleteToken()
    },
    SET_PASSWORD (context, body) {
      return IDInstance.operation.setPassword(body)
    },
    UPDATE_FEEDBACK_TYPE ({ commit }, payload) {
      commit('SET_FEEDBACK_TYPE', payload)
    },
    VALIDATE_TOKEN ({ commit }, token) {
      return IDInstance.operation.validateToken(token).then(res => {
        commit('SET_VALIDATED', res.data || res)
        return Promise.resolve(res)
      })
    },
    CREATE_USER (context, body) {
      return IDInstance.user.create(body)
    },
    GET_TIMEZONES ({ commit }) {
      return UtilitiesInstance.commons.getTimezones().then(res => {
        commit('SET_TIMEZONES', res)
        return res
      })
    },
    VALIDATE_USERNAME ({ commit }, username) {
      return IDInstance.operation.validateUsername(username).then(res => {
        commit('SET_USERNAME', username)
        return Promise.resolve(res)
      })
    },
    GET_PREFERENCES (context) {
      const pref = Cookies.get(`${env}mp_pref`)
        ? JSON.parse(
            atob(Cookies.get(`${env}mp_pref`), { domain: IDInstance.domain })
          )
        : {}
      context.commit('SET_PREF', pref)
      return pref
    },
    SET_PREFERENCES (context, pref) {
      try {
        const preferences = btoa(JSON.stringify(pref))
        Cookies.set(`${env}mp_pref`, preferences, { domain: IDInstance.domain })
        context.commit('SET_PREF', pref)
        return true
      } catch (err) {
        return err
      }
    },
    GO_TO_REDIRECT_URL (context) {
      IDInstance.operation
        .getRedirectUrl(process.env)
        .then(res => {
          window.location.assign(res)
        })
        .catch(() => {
          window.location.assign(process.env.VUE_APP_DEFAULT_REDIRECT)
        })
    },
    GET_USER_FAIL ({ commit }) {
      const totalUserFail = Cookies.get(`${env}mp_fl`)
        ? parseInt(
            atob(Cookies.get(`${env}mp_fl`), { domain: IDInstance.domain })
          )
        : 0
      commit('SET_USER_FAIL', totalUserFail)
    },
    SET_USER_FAIL ({ commit }, value) {
      commit('SET_USER_FAIL', value)
    },
    SET_SHOW_CAPTCHA ({ commit }, value) {
      commit('SHOW_CAPTCHA', value)
    },
    SET_REQUIRED_CAPTCHA_V2 ({ commit }, value) {
      commit('SET_REQUIRED_CAPTCHA_V2', value)
    },
  }
})

export default store
