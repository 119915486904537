<template lang="html">
  <div
    class="el-chunky eu-pa_5 emd-modal"
    :class="{ _active: isActive }"
  >
    <div class="emd-modal__content">
      <div class="emd-container _footer_centered">
        <div class="emd-container__content">
          <h2
            class="emd-text _size_section _color_contrast _align_center eu-mt_2"
          >
            {{ $t('TWOFA-RESET-SUCCESS.TITLE') }}
          </h2>
          <p class="emd-text _color_default _align_center eu-mt_5 eu-px_5">
            {{ $t('TWOFA-RESET-SUCCESS.DESCRIPTION') }}
          </p>
          <p class="emd-text _color_default _align_center eu-mt_5 eu-px_5">
            {{ $t('TWOFA-RESET-SUCCESS.DESCRIPTION-PART2') }}
            <span class="emd-text _type_bold">
              {{ $t('TWOFA-RESET-SUCCESS.DESCRIPTION-BOLD') }}
            </span>
            {{ $t('TWOFA-RESET-SUCCESS.DESCRIPTION-PART3') }}
          </p>
        </div>
        <div class="emd-container__footer el-chunky eu-pa_5">
          <button
            :disabled="isLoading"
            @click="login"
            class="emd-btn _color_primary eu-grow_1"
          >
            <emd-loader
              v-if="isLoading"
              type="_subtle"
            ></emd-loader>
            <span v-else>{{ $t('TWOFA-RESET-SUCCESS.CONFIRM-BUTTON') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EmdLoader } from 'emerald-vue/src/components'
import { env } from '@/store'
import recaptchaV3Verifier from '@/mixins/isRecaptchaV3.js'
import twofaRequired from '@/mixins/twofaRequired'

export default {
  name: 'TwoFaResetSuccess',
  components: {
    EmdLoader
  },
  mixins: [recaptchaV3Verifier, twofaRequired],
  computed: {
    isActive () {
      return this.$store.state.modal.resetSuccess
    },
    email () {
      return this.$store.state.tfaLoginBody?.username
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async login () {
      this.isLoading = true
      sessionStorage.removeItem(`${env}mp_si`)

      const body = this.$store.state.tfaLoginBody

      if (this.isRecaptchav3) {
        await this.$recaptchaLoaded()

        body.captcha_token = await this.$recaptcha('login')
      }

      this.$store
        .dispatch('CREATE_AUTHORIZATION', { body })
        .then(() => {
          this.$store.dispatch('SET_USER_FAIL', 0)
          this.$store.dispatch('UPDATE_SIGN_LOADING', true)
          this.$store.dispatch('GO_TO_REDIRECT_URL')
        })
        .catch((err) => {
          const error = err.body
          const description = error.error_description
          const tfaAllowedUser = this.componentIsEnabled('tfa', 'tfaLogin')

          if (this.isTfaError(description) && tfaAllowedUser) {
            if(this.isTfaCodeRequired(description)) {
              return this.tfaRedirect(error)
            } else if (this.isTfaCreateRequired(description)){
              return this.tfaCreate(error)
            }
          } else {
            this.$router.go()
          }
        })
        .finally(() => {
          this.$store.commit('updateResetSuccess')
          this.isLoading = false
        })
    }
  }
}
</script>
<style scoped>
.emd-container .emd-container__footer .emd-btn {
  margin-right: 0;
}
</style>
